import styled, { css } from 'styled-components';

export const QrViewWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  margin: 0 auto;
  gap: 40px;
  max-width: 500px;
  padding: 30px 10px;
  padding-top: 70px;
  box-sizing: border-box;
  min-height: inherit;
`;

export const QrViewLogo = styled.img`
  max-width: 100%;
  align-self: center;
`;

interface QrViewButtonProps {
  $color: string;
  $textColor: string;
}

export const QrViewButton = styled.button<QrViewButtonProps>`
  font-size: 16px;
  border-radius: 22px;
  color: ${({ $textColor }) => $textColor};
  background: ${({ $color }) => $color};
  padding: 13px 30px;
  border: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

interface QrViewTextProps {
  $color: string;
  $small?: boolean;
}

export const QrViewText = styled.div<QrViewTextProps>`
  font-size: 28px;
  line-height: 35px;
  color: ${({ $color }) => $color};
  text-align: center;
  align-self: center;

  ${({ $small }) =>
    $small &&
    css`
      font-size: 15px;
      line-height: 20px;
    `}
`;

interface QrViewInputProps {
  $color: string;
  $textColor: string;
}

export const QrViewInput = styled.input<QrViewInputProps>`
  padding: 14px 20px;
  background: transparent;
  border: 1px dashed ${({ $color }) => $color};
  color: ${({ $textColor }) => $textColor};
  font-size: 20px;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 22px;

  &:focus {
    outline: none;
  }
`;

interface QrViewErrorProps {
  $color: string;
}

export const QrViewError = styled.div<QrViewErrorProps>`
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.danger};
  background: white;
  border: 2px solid ${({ $color }) => $color};
  border-radius: 10px;
  text-align: center;
`;

export const QrViewPowered = styled.img`
  width: 150px;
  align-self: center;
  margin-top: '50px';
`;
