import { FC } from 'react';
import { QrSuccessText, QrSuccessTick, QrSuccessTickCheck, QrSuccessTickCircle, QrSuccessWrapper } from './qr-success.styles';

interface QrSuccessProps {
  color: string;
  text: string;
}

export const QrSuccess: FC<QrSuccessProps> = ({ color, text }) => {
  return (
    <QrSuccessWrapper>
      <div>
        <QrSuccessTick $color={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <QrSuccessTickCircle $color={color} cx="26" cy="26" r="25" />
          <QrSuccessTickCheck fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </QrSuccessTick>
      </div>
      <QrSuccessText $color={color}>{text}</QrSuccessText>
    </QrSuccessWrapper>
  );
};
