import { FC, useState } from 'react';
import { Flex } from '@app/layout/flex';
import { QrSuccess } from './qr-success';
import { LayoutItem } from '@app/layout/layout-item';
import { QrConfigModel } from '@app/__generated__/graphql';
import * as z from 'zod';
import { isColorDark } from '@app/utils/colors.util';
import { QrViewButton, QrViewError, QrViewInput, QrViewLogo, QrViewPowered, QrViewText, QrViewWrapper } from './qr-view.styles';

interface QrViewProps {
  template: QrConfigModel;
  isSuccess: boolean;
  isError: boolean;
  onSubmit: (email: string) => void;
  isLoading?: boolean;
  noValidate?: boolean;
}

export const QrView: FC<QrViewProps> = ({ template, isSuccess, isError, onSubmit, isLoading, noValidate }) => {
  const [email, setEmail] = useState('');

  const handleSend = () => {
    const result = z.string().email().safeParse(email);
    if (!result.success && !noValidate) return;
    onSubmit(email);
  };

  const isDark = isColorDark(template.bgColor);
  const bottomImg = `https://assets.picsane.tech/mail/powered_by_picsane${isDark ? '_white' : ''}.png`;

  if (isSuccess) return <QrSuccess color={template.color} text={template.successText} />;
  return (
    <QrViewWrapper>
      <Flex gap={30}>
        <Flex gap={30}>
          <QrViewLogo src={template.logoUrl} width={template.logoWidth} alt="logo" />
          <QrViewText
            $color={template.textColor}
            dangerouslySetInnerHTML={{ __html: template.headerText.replaceAll('\n', '<br/>') }}
          ></QrViewText>
        </Flex>
        <Flex gap={28}>
          <Flex gap={5}>
            <QrViewText $color={template.textColor} $small>
              {template.inputText}
            </QrViewText>
            <QrViewInput
              type="email"
              $color={template.color}
              $textColor={template.textColor}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Flex>
          <Flex gap={10}>
            {isError && <QrViewError $color={template.color}>Something went wrong... try again later</QrViewError>}
            <QrViewButton
              type="button"
              $color={template.color}
              $textColor={template.bgColor}
              onClick={handleSend}
              disabled={isLoading}
            >
              {template.buttonText}
            </QrViewButton>
          </Flex>
        </Flex>
      </Flex>
      <LayoutItem align="end" justify="center">
        {template.showBottomImg && (
          <a href="https://www.instagram.com/picsane.tech/" target="_blank">
            <QrViewPowered src={bottomImg} alt="powered by picsane" />
          </a>
        )}
      </LayoutItem>
    </QrViewWrapper>
  );
};
