import { fadeIn } from '@app/styles/animations/fade-in';
import { topSlideIn } from '@app/styles/animations/top-slide-in';
import styled from 'styled-components';

import { keyframes } from 'styled-components';

export const QrSuccessWrapper = styled.div`
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const QrSuccessText = styled.div<{ $color: string }>`
  text-align: center;
  font-size: 16px;
  color: ${({ $color }) => $color};
  animation:
    ${topSlideIn} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards,
    ${fadeIn} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation-delay: 0.7s;
  animation-fill-mode: both;
`;

const TickStroke = keyframes`
    100% {
        stroke-dashoffset: 0;
    }
`;

export const QrSuccessTick = styled.svg<{ $color: string }>`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${({ $color }) => $color};
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #8b25ff;
  position: relative;

  margin: 0 auto;
`;

export const QrSuccessTickCircle = styled.circle<{ $color: string }>`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${({ $color }) => $color};
  fill: transparent;
  animation: ${TickStroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

export const QrSuccessTickCheck = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${TickStroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`;
